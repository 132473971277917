<template>
  <div id="banners-container">
    <div class="warning-banner p-3" v-if="store.altert">
      <div class="global-container">
        <p class="notif"><v-lazy-image :src="getPicto('alert', 'svg', 'classique')" alt="Alerte" /></p>
        <p class="marquee"><span><strong>{{ store.altert }} </strong></span></p>
      </div>
    </div>
    <div class="ad-banner p-3" v-if="store.banner.content">
      <div class="global-container">
        <div class="d-flex">
          <p class="notif"><v-lazy-image :src="getPicto('notifications', 'svg', 'classique')" alt="Publicité" /></p>
          <p class="marquee">
            <span><strong>{{ store.banner.content }} </strong> </span>
          </p>
        </div>
        <p class="notif notif-ban">
          <a :href="store.banner.link">{{ store.banner.wordingCTA }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import UtilMixin from "../../../mixins/UtilMixin";
import VLazyImage from "v-lazy-image/v2";
import store from '../../../store/header'
export default {
  name: "BannersModule",
  components: {
    VLazyImage,
  },
  mixins: [UtilMixin],
  data() {
    return {
      bannersContainerHeight : 0,
      store,
    }
  },
  methods: {
    isMobile() {
      return screen.width <= 1200;
    },
  }
};
</script>
<style lang="scss">
  @import "../../../assets/sass/modules/_bannersModule.scss";
</style>

